.loading.cover-content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.loading.cover-page {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.loading .ant-spin {
    color: #3c8dbc;
}
.webviewer {
    flex: 1;
    height: 100%;
    margin: 8px;
    -webkit-box-shadow: 1px 1px 10px #999;
            box-shadow: 1px 1px 10px #999;
}
.dropDownclass{
    
        position: fixed;
        top: 100px;
        right: 10px;
      
}
@media print {
    @media all {
        .page-break {
            display: none;
        }
    }
  
    @media print {
        html, body {
            height: initial !important;
            overflow: initial !important;
            -webkit-print-color-adjust: exact;
        }
        .page-break {
            margin-top: 1rem;
            display: block;
            page-break-after: always;
        }
    }
}